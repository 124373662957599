var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "webkit-grid-container-cell" }, [
    _c(
      "div",
      { staticClass: "webkit-grid-parent" },
      _vm._l(_vm.displayedBlocks, function(block, index) {
        return _c(
          "a",
          {
            key: index,
            staticClass: "webkit-grid-item",
            class: _vm.background ? "border-color2" : "border-color1",
            attrs: { href: block.url || false, target: block.target }
          },
          [
            _c("div", { staticClass: "webkit-grid-item-grid " }, [
              _c("div", { staticClass: "webkit-grid-cell-background" }),
              _vm._v(" "),
              _c("div", { staticClass: "webkit-grid-cell-img" }, [
                _c("div", {
                  staticClass: "webkit-grid-image bg-img",
                  style: {
                    backgroundImage: "url('" + block.image + "')"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "webkit-grid-spacing-above-text" }),
              _vm._v(" "),
              _c("div", {
                staticClass: "webkit-grid-heading",
                domProps: { innerHTML: _vm._s(block.heading) }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "webkit-grid-cell-heading2" }, [
                (block.datum && _vm.showdate) || block.heading2
                  ? _c("div", {
                      staticClass: "webkit-grid-heading2",
                      domProps: {
                        innerHTML: _vm._s(
                          block.heading2 ? block.heading2 : block.datum
                        )
                      }
                    })
                  : _vm._e()
              ]),
              _vm._v(" "),
              block.text
                ? _c("div", {
                    staticClass: "webkit-grid-text",
                    domProps: { innerHTML: _vm._s(block.text) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "webkit-grid-button-cell" }, [
                block.url
                  ? _c("div", { staticClass: "webkit-grid-button" }, [
                      _c("div", { staticClass: "webkit-btn__big" }, [
                        _vm._v("zum Projekt")
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.blocks.length > _vm.numberOfItemsToDisplay && _vm.background == "1"
      ? _c(
          "div",
          {
            staticClass: "webkit-gallery-load-more-button-container",
            on: { click: _vm.showMore }
          },
          [
            _vm.background == "1"
              ? _c(
                  "div",
                  { staticClass: "webkit-btn__small bg-color1 text-color2" },
                  [_c("i", { staticClass: "fal fa-arrow-down" })]
                )
              : _c("div", { staticClass: "webkit-btn__small bg-color2" }, [
                  _c("i", { staticClass: "fal fa-arrow-down" })
                ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }