import { gsap, TweenLite } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
 import * as animations from 'theme/theme/animations.config.js';
import * as IEnvironment from '@libTs/env';
gsap.registerPlugin( ScrollTrigger );

let animationActive: boolean;
let scrollerPosition = animations.scrollerPosition;

const ENV = IEnvironment.ENV;

if ( ENV.isProduction ) {
  animationActive = ENV.name != 'dev' && animations.animationStaging;
} else {
  animationActive = animations.animationDev;
}

if ( window.innerWidth <= 480 ) {
  scrollerPosition = animations.scrollerPositionMobile;
} else {
  scrollerPosition = animations.scrollerPosition;
}

function reloaded() {
  ScrollTrigger.refresh( true );
}

$( window ).on( "load", reloaded );

if ( animationActive ) {
  $( () => {
    const modularContent = gsap.utils.toArray( '.fade-in-animation' );

    modularContent.forEach( function ( value: HTMLElement ) {
      const classList = animations.modulesToNotAnimate as Array<string>;
      const child: HTMLElement = value.firstChild as HTMLElement;
      if ( classList.some( className => child.classList.contains( className ) ) ) {
        return;
      } else {
        const anim = TweenLite.fromTo(
          value,
          {
            autoAlpha: 0,
            y: animations.yStartpoint,
            x: animations.xStartpoint,
          },
          {
            autoAlpha: 1,
            y: animations.yEndpoint,
            x: animations.xEndpoint,
            duration: 1 * animations.animationDurationMultiplier,
            clearProps: "all"
          }
        );
        ScrollTrigger.create( {
          trigger: value,
          start: `top ${scrollerPosition}`,
          animation: anim,
          markers: animations.activateMarkers,
          toggleActions: animations.toggleActions,
        } );
      }
    } );
  } );
}