<template>
  <div class="webkit-grid-container-cell">
    <div class="webkit-grid-parent"  >
      <a
        class="webkit-grid-item"
        v-bind:key="index"
        v-for="(block, index) in displayedBlocks"
        v-bind:href="block.url || false"
        :target="block.target"
        v-bind:class="background ? 'border-color2' : 'border-color1'"
      >
        <div class="webkit-grid-item-grid ">
          <div class="webkit-grid-cell-background"></div>
          <div class="webkit-grid-cell-img">
            <div
              class="webkit-grid-image bg-img"
              v-bind:style="{
                backgroundImage: 'url(\'' + block.image + '\')',
              }"
            ></div>
          </div>
          <div class="webkit-grid-spacing-above-text"></div>
          <div class="webkit-grid-heading" v-html="block.heading"></div>
          <div class="webkit-grid-cell-heading2">
            <div
              class="webkit-grid-heading2"
              v-if="(block.datum && showdate) || block.heading2"
              v-html="block.heading2 ? block.heading2 : block.datum"
            ></div>
          </div>
          <div
            class="webkit-grid-text"
            v-if="block.text"
            v-html="block.text"
          ></div>
          <div class="webkit-grid-button-cell">
            <div class="webkit-grid-button" v-if="block.url">
              <div class="webkit-btn__big">zum Projekt</div>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div
      class="webkit-gallery-load-more-button-container"
      v-if="blocks.length > numberOfItemsToDisplay && background == '1'" 
      v-on:click="showMore"
    >
      <div class="webkit-btn__small bg-color1 text-color2" v-if="background == '1'">
        <i class="fal fa-arrow-down"></i>
      </div>
      <div class="webkit-btn__small bg-color2" v-else>
        <i class="fal fa-arrow-down"></i>
      </div>
    </div>
    <div
    >

    </div>
  </div>

</template>

<script>
import Vue, { VNode } from 'vue';
import * as gridConfig from 'theme/theme/modules/grid/theme.grid.config';

export default Vue.extend({
  name: 'WebkitGrid',
  props: {
    blocks: Array,
    showdate: String,
    background: String,
  },
  data() {
    return {
      numberOfItemsToDisplayR: gridConfig.loadMore.numberOfItemsToDisplayR,
      numberOfItemsToDisplay: gridConfig.loadMore.numberOfItemsToShow,
      increment: gridConfig.loadMore.numberOfItemsIncrement,
      displayedBlocks: [],
      displayedBlocksR: [],
    };
  },
  methods: {
    showMore: function () {
      this.numberOfItemsToDisplay += this.increment;
      this.numberOfItemsToDisplayR += this.increment;
      this.numberOfItemsToDisplay = Math.min(
        this.numberOfItemsToDisplay,
        this.blocks.length
      );
      this.numberOfItemsToDisplayR = Math.min(
        this.numberOfItemsToDisplayR,
        this.blocks.length
      )
      this.displayedBlocks = this.blocks.slice(0, this.numberOfItemsToDisplay);
    },
  },
  created() {
    this.displayedBlocks = this.blocks.slice(0, this.numberOfItemsToDisplay);
  },
});
</script>
