import { VDirective } from '@libTs/vue-base';
import { DirectiveBinding } from 'vue/types/options';

export const overlay: VDirective = [
  'overlay',
  {
    bind( el: HTMLElement, binding: DirectiveBinding ): void {
      if ( binding.arg == 'show' ) {
        el.addEventListener( 'click', function () {
          $( '.webkit-header-nav-overlay' ).addClass( 'overlay-active' );
        } );
      }

      if ( binding.arg == 'hide' ) {
        el.addEventListener( 'click', function () {
          $( '.webkit-header-nav-overlay' ).removeClass( 'overlay-active' );
        } );
      }
    },
  },
];
