<template>
  <div
    class="webkit-slideshow-container"
    :class="index == 1 ? 'webkit-spacing-first' : 'webkit-spacing-normal'"
  >
    <div class="webkit-slideshow" v-if="slides.length > 1">
      <agile :throttleDelay="0" :autoplay="true" :autoplaySpeed="4000">
        <div class="slide grid" v-for="slide in slides">
          <div
            class="webkit-slide-image col-start-1 col-end-1 row-start-1 row-end-1"
            v-bind:style="{ backgroundImage: 'url(\'' + slide.image + '\')', backgroundPosition:  slide.focalPoint.x * 100 + '% '  +   slide.focalPoint.y * 100 + '%' }"
          >
            <img class="img-hidden" :src="slide.image" :alt="slide.alt" />
          </div>
          <div
            class="webkit-slide-text-container"
            :class="slide.text ? 'webkit-slide-bg' : ''"
          >
            <div class="webkit-slide-heading">{{ slide.heading }}</div>
            <div class="webkit-slide-button-container" v-if="slide.link">
              <a class="webkit-slide-button" :href="slide.link">
                <div class="webkit-btn__big" v-if="slide.linkText">
                  {{ slide.linkText }}
                </div>
                <i class="webkit-btn__small webkit-arrow-right" v-else></i>
              </a>
            </div>
          </div>
          <div class="webkit-slide-text" v-if="slide.text">{{ slide.text }}</div>
        </div>
      </agile>
    </div>

    <div class="webkit-slideshow" v-else-if="slides.length > 0">
      <div class="slide grid">
        <div
          class="webkit-slide-image col-start-1 col-end-1 row-start-1 row-end-1"
          v-bind:style="{ backgroundImage: 'url(\'' + slides[0].image + '\')', backgroundPosition:   slides[0].focalPoint.x * 100 + '% '  +   slides[0].focalPoint.y * 100 + '%'}"
        >
          <img class="img-hidden" :src="slides[0].image" :alt="slides[0].alt" />
        </div>
        <div
          class="webkit-slide-text-container"
          :class="slides[0].text ? 'webkit-slide-bg' : ''"
        >
          <div class="webkit-slide-heading">{{ slides[0].heading }}</div>
          <div class="webkit-slide-button-container" v-if="slides[0].link">
            <a class="webkit-slide-button" :href="slides[0].link">{{
              slides[0].linkText
            }}</a>
          </div>
        </div>
        <div class="webkit-slide-text" v-if="slides[0].text">{{ slides[0].text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { VueAgile } from "vue-agile";

export default Vue.extend({
  name: "SlideShow",
  props: ["slides", "index"],
  data: function() {
    //read slider config from config file
    return {
      autoplay: true,
    };
  },
  components: {
    agile: VueAgile,
  },
});
</script>
