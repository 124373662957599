var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "webkit-akkordeon-entry" }, [
    _c(
      "div",
      {
        staticClass: "webkit-akkordeon-title",
        on: {
          click: function($event) {
            _vm.show = !_vm.show
          }
        }
      },
      [
        _c("div", { staticClass: "webkit-akkordeon-title-text" }, [
          _vm._v(_vm._s(_vm.heading))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "webkit-akkordeon-icon-container" }, [
          _c("i", {
            class: {
              "fal fa-angle-up webkit-akkordeon-active": _vm.show,
              "fal fa-angle-down": !_vm.show
            },
            attrs: { "aria-hidden": "true" }
          })
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "akkordeonContainer",
        staticClass: "webkit-akkordeon-text-container",
        class: { "akkordeon-active": _vm.show }
      },
      [
        _vm.text
          ? _c("div", {
              ref: "akkordeonText",
              staticClass: "webkit-akkordeon-text webkit-redactor-content",
              domProps: { innerHTML: _vm._s(_vm.text) }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "akkordeonLinks",
            staticClass: "col-start-2 col-end-24 row-start-2 row-span-1"
          },
          [
            _vm._t("download"),
            _vm._v(" "),
            _vm.linknewwinow
              ? _c("div", { staticClass: "new-tab-container" }, [
                  _c(
                    "a",
                    {
                      staticClass: "open-new-tab ",
                      attrs: { href: _vm.linknewwinow, target: "_blank" }
                    },
                    [
                      _c("i", { staticClass: "fas fa-angle-right tet-color4" }),
                      _vm._v(" " + _vm._s(_vm.linknewwinowtext))
                    ]
                  )
                ])
              : _vm._e()
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }