const gridColsPerScreen = {
  default: 2,
  xl: 2,
  l: 2,
  m: 2,
  s: 1,
  xs: 1
}

module.exports = {
  backgroundColor: 'bg-none',
  backgroundColorCell: 'bg-transparent',
  headingFont: 'font-f2',
  loadMore: {
    numberOfItemsToShow: 4,
    numberOfItemsIncrement: 4,
    numberOfItemsToShowR: 2,
    numberOfItemsIncrementR: 4, 
  },

  heading: {
    font: 'font-f2 text-color1',
  },

  heading2: {
    font: 'font-f4 text-color1',
  },

  text: {
    font: 'font-f5 text-color1',
  },

  // not used in 1. theme -> fixed to pt-1/1,
  aspectRatio: 'pt-13/24 m:pt-4/3 xs:pt-3/2',

  // The number of columns to display in different screen sizes
  colsPerScreen: gridColsPerScreen,
  cols: `grid-cols-${gridColsPerScreen.default}
         xl:grid-cols-${gridColsPerScreen.xl}
         l:grid-cols-${gridColsPerScreen.l}
         m:grid-cols-${gridColsPerScreen.m}
         s:grid-cols-${gridColsPerScreen.s}
         xs:grid-cols-${gridColsPerScreen.xs}`,
};