import { VDirective } from '@libTs/vue-base';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { DirectiveBinding } from 'vue/types/options';

gsap.registerPlugin( ScrollToPlugin );

export const scroll: VDirective = [
  'scroll',
  {
    bind( el: HTMLElement, binding: DirectiveBinding ): void {
      el.addEventListener( 'click', function () {
        gsap.to( window, {
          duration: 1,
          scrollTo: {y:binding.value, offsetY:50 } ,
        } );
      } );
    },
  },
];
